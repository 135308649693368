.tech-stack-title {
    font-family: 'Kompot Display Demo', 'Times New Roman', serif;
    font-size: 3rem;
    margin-bottom: 1rem;

}

.tech-stack-list {
    display: flex;
    flex-direction: column;

}

.tech-stack-item {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stack-headline {
    text-align: center;
}

.stack-headline ::after {
    content: '';
    border-bottom: 3px solid rgb(146, 225, 251);
    width: 20rem;
    display: block;
    margin-bottom: 1rem;

}

.projects {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.project-details {

    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 1rem;
    /* background-color: #7E77AA; */
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 3px 4px 7px 1px rgba(160,228,255,0.87);
}


.title {
    display: block;
    text-transform: uppercase;
    margin: 0.5rem auto 1rem 0;
    font-family: 'Calps Sans', sans-serif;
    color:  rgba(160,228,255,0.87);
    font-weight: 400;

}

.project-details > span {
    line-height: 120%;
}

.stack-item-row {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
}

.stack-item {
    padding: 0.2rem;
    background-color: #7E77AA;
    margin: 0.2rem 1rem 0 0;
    border-radius: 10px;
}

.stack-item:hover{
    background-color:  rgba(160,228,255,0.87);
}


@media (min-width:1000px) {
    .project-details {
        width: 40%;
    }
}