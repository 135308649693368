.icons {
    display: flex;
}


.gridbox-shortcuts-nav-links {
    display: none;
    margin-left: 3rem;
}

.social-icons {
    margin: 0.5rem;
    width: 2rem;
    cursor: pointer;
}


.img-wrapper {
    display: flex;
    justify-items: center;
    align-items: center;
}

.img-wrapper a {
    transition: .3s ease-in-out;
    display: flex;
    margin: auto;
}

.img-wrapper a:hover {
    background-color: white;
    border-radius: 15px;
} 



@media (min-width:800px) {
    .icons {
        margin-left: 2rem;
    }
}
  