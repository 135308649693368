.language-toggle {
    display: flex;
    width: 10rem;
    align-items: baseline;
}   

.language-toggle span {
    color: #A0E4FF;
    font-family: 'Calps Sans', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    -webkit-transition: font-size 1s ease-in-out;
    -moz-transition: font-size 1s ease-in-out;
    -o-transition: font-size 1s ease-in-out;
    transition: font-size 1s ease-in-out;
}  

.language-toggle span:hover {
    border-bottom: 3px solid #7E77AA;
    font-size: 1.3rem;
    cursor: pointer;
}

.language-toggle-icon {
    padding: 0.8rem;
    margin: 0 1rem 0 0;

}

img {
    width: 100%;
}

#bigbenpic {
    width: 120%;
}

@media (max-width:800px) {

    .language-toggle {
        margin-bottom: 3rem;
    }
}