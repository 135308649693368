a {
    font-weight: 800;
    color: #7E77AA;
}

#root {
    white-space: pre-line;
}

body {
    font-family: Arimo, Helvetica, sans-serif;
    background-color: #070406;
    width: 100vw;
    line-height: 1.2rem;
}

main {
    width: 100%;
}
.main-container {

    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 5rem auto;
    border-radius: 2rem;
    padding: 0.4em;

}

.header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


#baseline {
    display: flex;
    font-family: 'Kompot Display Demo', 'Times New Roman', Times, serif;
    font-size: 4rem;
    color: #A0E4FF;
    margin-bottom: 2rem;

}

.header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}



/* Fonts imports */
@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('../fonts/Arimo/Arimo-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calps Sans';
    src: url('../fonts/CalpsSans/CalpsSans-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calps Sans SemiLight';
    src: url('../fonts/CalpsSans/CalpsSans-SemiLight.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calps Sans';
    src: url('../fonts/CalpsSans/CalpsSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calps Sans';
    src: url('../fonts/CalpsSans/CalpsSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calps Sans';
    src: url('../fonts/CalpsSans/CalpsSans-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calps Sans SemiLight';
    src: url('../fonts/CalpsSans/CalpsSans-SemiLightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Kompot Display Demo';
    src: url('../fonts/KompotDisplayDemo/KompotDisplayDemo.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}





