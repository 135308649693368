.gridbox-nav {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.thumbnail {
    padding: 0.5rem;
}


.nav-links > a {
    color: #A0E4FF;
    text-decoration: none;
}

.nav-links > a:hover {
    font-weight: 800;
}

.nav-links {
    display: flex;
    justify-content: space-around;
    font-family: 'Calps Sans', Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
}


.gridbox-shortcuts-nav ul li {
    margin-bottom: 0.6rem;
    text-align: center;
}


@media (min-width:800px) {

    .thumbnail {
        border-top-left-radius: 15px;
        border-top-right-radius: 25px;
        padding: 0.8rem 1.5rem;

    }

    .active {
        background-color: #292B40;
    }
    
    .inactive {
        background-color: #7E77AA;
    }


    .flex-navigation {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        /* margin: 2rem auto;  */
        
    
    }



}


@media (max-width:800px) {
    .gridbox-nav {
        align-items: center;
        justify-content: center;
    }

    .nav-links {
        flex-direction: column;
        align-items: center;
        justify-items: center;
        border-radius: 15px;
        margin-right: 0.5rem;
    }

    .nav-links a {
        background-color: none;
    }

    .nav-links a:hover {
        background-color: #7E77AA;
        border-radius: 10px;

    }

    .flex-navigation {
        justify-content: center;
        margin-bottom: 2rem;
    
    }
    

}
