.lifeline {
    background-color: #292B40;
    color: rgb(238, 248, 255);
    width: 90%;
    height: 120vh;
    padding: 2rem;
}

.cv-wrapper {
    max-width: 90%;
    height: 100%;
    margin: auto;
}